import React from "react";
import "./Banner.scss";

export default function Banner() {
  return (
    <div className="banner">
      <h3 className="banner__heading">Future Staff</h3>
      <p className="banner__text">
        Grace is presently seeking a music minister and pastoral intern. For
        more information, please contact the church office at{" "}
        <a href="tel:636-296-2380">636-296-2380</a>.
      </p>
    </div>
  );
}
