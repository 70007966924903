import React from "react";
import "./SermonCard.scss";
import cross from "../../assets/images/cross.jpg";
import { videoSermon } from "../../types/sermonInterface";
import { Link } from "react-router-dom";

interface SermonCardProps {
  sermon: videoSermon;
}
const SermonCard: React.FC<SermonCardProps> = ({ sermon }) => {
  return (
    <Link
      key={sermon.videoId}
      to={`/sermon/${sermon.videoId}`}
      className="sermonCard"
    >
      <section className="sermonCard__wrapper">
        <img
          src={sermon.image ?? cross}
          alt="Sermon Series"
          className="sermonCard__img"
        />
      </section>
      <section className="sermonCard__content">
        <h5 className="sermonCard__title">{sermon.title}</h5>
        <p className="sermonCard__reference">{sermon.ref}</p>
        <p className="sermonCard__desc">{sermon.desc}</p>
        <p className="sermonCard__date">
          {new Date(sermon.date + "T00:00:00").toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
      </section>
    </Link>
  );
};

export default SermonCard;
