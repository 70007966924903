import React, { useEffect } from "react";
import "./AdminHelp.scss";
import { Link, useLocation } from "react-router-dom";
import HeroSmall from "../../components/HeroSmall/HeroSmall";

export default function AdminHelp() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <div className="adminHelp">
      <aside className="adminHelp__nav">
        <ul className="adminHelp__list">
          <li className="adminHelp__item">
            <a
              href="#sermon"
              className="adminHelp__link adminHelp__link--header"
            >
              <p className="adminHelp__link-text">Sermon</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a href="#sermonEdit" className="adminHelp__link">
              <p className="adminHelp__link-text">Edit a Sermon</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a href="#sermonDelete" className="adminHelp__link">
              <p className="adminHelp__link-text">Delete a Sermon</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a href="#sermonAdd" className="adminHelp__link">
              <p className="adminHelp__link-text">Add a Sermon</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a
              href="#slides"
              className="adminHelp__link adminHelp__link--header"
            >
              <p className="adminHelp__link-text">Slides</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a href="#slidesEdit" className="adminHelp__link">
              <p className="adminHelp__link-text">Edit a Slide</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a href="#slidesDelete" className="adminHelp__link">
              <p className="adminHelp__link-text">Delete a Slide</p>
            </a>
          </li>
          <li className="adminHelp__item">
            <a href="#slidesAdd" className="adminHelp__link">
              <p className="adminHelp__link-text">Add a Slide</p>
            </a>
          </li>
        </ul>
      </aside>
      <section className="adminHelp__content">
        <HeroSmall
          content="Admin Help"
          classtitle="heroSmall heroSmall--admin"
        />
        <h1 id="sermon" className="adminHelp__section">
          Sermons
        </h1>
        <article id="sermonEdit" className="adminHelp__article">
          <h2 className="adminHelp__title">Edit a Sermon</h2>
          <ol className="adminHelp__steps">
            <li className="adminHelp__step">
              Navigate to{" "}
              <Link to={"sermon"} className="adminHelp__inline">
                Sermons Page.
              </Link>
            </li>
            <li className="adminHelp__step">
              Once the page loads, you will see the list of all current sermons.
              From here you can scroll through to see all data.
            </li>
            <li className="adminHelp__step">
              To change a field, either click on the cell/text you want to
              change and start typing, or double click to be able to copy and/or
              paste.
            </li>
            <li className="adminHelp__step">
              To save your changes, click the button "Save Data"
            </li>
          </ol>
          <p className="adminHelp__ttn">Things to note:</p>
          <ul className="adminHelp__steps--special">
            <li className="adminHelp__step adminHelp__step--special">
              The "Timestamp" cell must be in 12:34 format.
            </li>
            <li className="adminHelp__step adminHelp__step--special">
              The "Image" cell refers to the name of the file. If the last item
              containing an image is to be deleted, follow the Delete a Sermon
              instructions to make sure the image file gets deleted. (If simply
              erased from the grid without using "Delete Sermon" the file will
              NOT be deleted)
            </li>
          </ul>
        </article>
        <article id="sermonDelete" className="adminHelp__article">
          <h2 className="adminHelp__title">Delete a Sermon</h2>
          <ol className="adminHelp__steps">
            <li className="adminHelp__step">
              Navigate to{" "}
              <Link to={"sermon"} className="adminHelp__inline">
                Sermons Page.
              </Link>
            </li>
            <li className="adminHelp__step">
              Once the page loads, you will see the list of all current sermons.
              From here, click the checkbox to the left of the sermon(s) you
              would like to delete.
            </li>
            <li className="adminHelp__step">
              Once selected, click the "Delete Selected" button.
            </li>
            <li className="adminHelp__step">
              To save your changes, click the button "Save Data"
            </li>
          </ol>
        </article>
        <article id="sermonAdd" className="adminHelp__article">
          <h2 className="adminHelp__title">Add a Sermon</h2>
          <ol className="adminHelp__steps">
            <li className="adminHelp__step">
              Navigate to{" "}
              <Link to={"sermon"} className="adminHelp__inline">
                Sermons Page.
              </Link>
            </li>
            <li className="adminHelp__step">
              Once the page loads, you will see the list of all current sermons.
              From here click the "Add Sermon" button.
            </li>
            <li className="adminHelp__step">
              Fill out all the data in the pop up.
            </li>
            <li className="adminHelp__step">Click "Upload Sermon" button.</li>
            <li className="adminHelp__step">
              If you don't want to upload the sermon and want to go back, click
              outside of the pop up to close it.
            </li>
          </ol>
          <p className="adminHelp__ttn">Things to note:</p>
          <ul className="adminHelp__steps--special">
            <li className="adminHelp__step adminHelp__step--special">
              The "Timestamp" cell must be in 12:34 format.
            </li>
            <li className="adminHelp__step adminHelp__step--special">
              Audio files must be mp3 files.
            </li>
            <li className="adminHelp__step adminHelp__step--special">
              Series Image supports jpg, jpeg, and png files.
            </li>
          </ul>
        </article>
        <h1 id="slides" className="adminHelp__section">
          Slides
        </h1>
        <article id="slidesEdit" className="adminHelp__article">
          <h2 className="adminHelp__title">Edit a Slide</h2>
          <ol className="adminHelp__steps">
            <li className="adminHelp__step">
              Navigate to{" "}
              <Link to={"slides"} className="adminHelp__inline">
                Slides Page.
              </Link>
            </li>
            <li className="adminHelp__step">
              Once the page loads, you will see the list of all current slides.
            </li>
            <li className="adminHelp__step">
              To change a field, either click on the cell/text you want to
              change and start typing, or double click to be able to copy and/or
              paste.
            </li>
            <li className="adminHelp__step">
              To save your changes, click the button "Save Data"
            </li>
          </ol>
          <p className="adminHelp__ttn">Things to note:</p>
          <ul className="adminHelp__steps--special">
            <li className="adminHelp__step adminHelp__step--special">
              The "Slide Image" refers to the name of the file and is not
              editable.
            </li>
          </ul>
        </article>
        <article id="slidesDelete" className="adminHelp__article">
          <h2 className="adminHelp__title">Delete a Slide</h2>
          <ol className="adminHelp__steps">
            <li className="adminHelp__step">
              Navigate to{" "}
              <Link to={"slides"} className="adminHelp__inline">
                Slides Page.
              </Link>
            </li>
            <li className="adminHelp__step">
              Once the page loads, you will see the list of all current slides.
              From here, click the checkbox to the left of the slide(s) you
              would like to delete.
            </li>
            <li className="adminHelp__step">
              Once selected, click the "Delete Selected" button.
            </li>
            <li className="adminHelp__step">
              To save your changes, click the button "Save Data"
            </li>
          </ol>
        </article>
        <article id="slidesAdd" className="adminHelp__article">
          <h2 className="adminHelp__title">Add a Slide</h2>
          <ol className="adminHelp__steps">
            <li className="adminHelp__step">
              Navigate to{" "}
              <Link to={"sermon"} className="adminHelp__inline">
                Slides Page.
              </Link>
            </li>
            <li className="adminHelp__step">
              Once the page loads, you will see the list of all current slides.
              From here click the "Add Image" button.
            </li>
            <li className="adminHelp__step">
              Choose file and provide a short descriptive text.
            </li>
            <li className="adminHelp__step">Click "Upload Image" button.</li>
            <li className="adminHelp__step">
              If you don't want to upload the image and want to go back, click
              outside of the pop up to close it.
            </li>
          </ol>
          <p className="adminHelp__ttn">Things to note:</p>
          <ul className="adminHelp__steps--special">
            <li className="adminHelp__step adminHelp__step--special">
              Image supports jpg, jpeg, and png files. The slides are best in
              16:9 aspect ratio.
            </li>
          </ul>
        </article>
      </section>
    </div>
  );
}
