export const FAQContent = [
  {
    question: "What are services like?",
    answer: `Services are comprised of a biblically based sermon, songs and hymns of varying musical styles, Scripture readings, prayers, and other core elements of historic Christian worship. Weekly worship services range from 60-75 minutes in length. Our Midweek gathering is more informal, and focuses on fellowship, prayer, and study.`,
  },
  {
    question: "What's available for my children?",
    answer: `At the 9:30 Sunday School/Discipleship hour, we have age-based classes for children from infants to teenagers. 

    During the 10:30 Worship time, we provide Nursery care for infants-2 years old, a kid-friendly class for kids ages 2-5 years old, and a Junior Church class for Kindergarten - Fourth Grade.`,
  },
  {
    question: "Do you provide childcare during the service?",
    answer: `Yes! Our highest priorities are to provide an environment of safety, love, and respect where children learn about God's love and God's Word. All Nursery helpers exhibit Christ-like patience and love, are active church members, and have background checks on file.

    \n\n We believe everything that transpires in the Nursery is confidential, including the emotional, physical, medical, and intellectual condition of every child. We believe the parent knows what is best for the child and will honor their requests as long as it is in alignment with our Nursery Ministry Policy. Physical punishment, scolding, and harsh words of any kind are forbidden!
    
    \n\n When you drop your baby off, we will be sure to get any relevant information on their needs, and we will be sure to send the parent a text message notification during the service if any unmanageable problems arise. 
    
    \n\n We pray for our babies and toddlers, and teach them about God, His love, and Scripture. We use tools such as First Steps D6 Curriculum to teach fundamental Bible truths though prayer, play and song. These truths are encouraged through Parent Pages and Cuddle cards families may take home.
    `,
  },
  {
    question: "What if I'm new to church?",
    answer: `You are welcome regardless of whether this is your first time in a church service, or you've moved to the area and are searching for a new church home (and everything in-between!). We are all learning and growing together, so that means no one knows everything. We welcome people with questions, issues, and hang-ups about the Christian faith. After all, we all have things we believe that we need to understand better. Come and learn with us!`,
  },
  {
    question: "What should I wear?",
    answer: `You will find a range of dress styles among our regular attendees—from more formal attire to everyday casual.​ There is no dress code, so please, simply come as you are. `,
  },
];
