import { useState } from "react";
import "./FAQCard.scss";

export default function FAQCard(props: { question: string; answer: string }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="FAQCard" onClick={() => setIsOpen(!isOpen)}>
      <hr className="FAQCard__line" />
      <section className="FAQCard__container" key={Math.random()}>
        <h3 className="FAQCard__question">{props.question}</h3>
        {isOpen ? (
          <i
            className="fa-solid fa-circle-xmark fa-xl"
            style={{ color: "#176476" }}
          ></i>
        ) : (
          <i
            className="fa-solid fa-circle-plus fa-xl"
            style={{ color: "#176476" }}
          ></i>
        )}
      </section>
      {isOpen && (
        <div className="FAQCard__answer">
          {props.answer.split("\n\n").map((paragraph) => (
            <p key={paragraph} className="FAQCard__paragraph">
              {paragraph}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}
