import React from "react";
import "./ModalCarousel.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import { ModalsContextType } from "../../types/modalsInterface";

export default function ModalCarousel({
  setModCarouselOpen,
}: Pick<ModalsContextType, "setModCarouselOpen">) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      cAlt: "",
      image: null,
    },
  });

  const submitHandler = (data: any) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append("image", data.image[0]);
    async function uploadCarousel() {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/Carousel/upload`,
        formData,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    toast
      .promise(
        uploadCarousel(),
        {
          loading: "Uploading Carousel...",
          success: "Carousel Uploaded",
          error: "Failed to Upload Carousel.",
        },
        { duration: 4750, className: "toast" }
      )
      .then(() => {
        reset();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to send message:", error);
      });
  };

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      setModCarouselOpen(false);
    }
  };

  return (
    <div className="modalCarousel" onClick={handleClickOutside}>
      <form
        className="modalCarousel__form"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(submitHandler)}
      >
        <h1 className="modalCarousel__header">Upload New Image</h1>
        <section className="modalCarousel__body">
          <div className="modalCarousel__wrapper">
            <label className="modalCarousel__label">
              <p>
                <span className="modalCarousel__req">*</span>Image Description
              </p>
              <input
                {...register("cAlt")}
                type="text"
                className="modalCarousel__input"
                placeholder="A cross on a hill...A Man Praying..."
                required
              />
            </label>
          </div>
          <div className="modalCarousel__wrapper modalCarousel__wrapper--fileNotice">
            <section className="modalCarousel__files">
              <label className="modalCarousel__label">
                <p>
                  <span className="modalCarousel__req">*</span>Slide Image
                </p>
                <input
                  {...register("image")}
                  type="file"
                  className="modalCarousel__input--file"
                  required
                />
              </label>
            </section>
            <section className="modalCarousel__notice">
              <p className="modalCarousel__notice-text">
                <span className="modalCarousel__req">*</span>
                Indicates a required field
              </p>
            </section>
          </div>
          <div className="modalCarousel__button-wrapper">
            <input
              type="submit"
              value="Upload Image"
              className="button button--adminSubmit"
            />
          </div>
        </section>
      </form>
    </div>
  );
}
