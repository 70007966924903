import React from "react";
import { Link } from "react-router-dom";
import "./AdminHome.scss";
import bibleOpen from "../../assets/images/bibleOpen1.jpg";
import cross from "../../assets/images/cross.jpg";

export default function AdminHome() {
  return (
    <div className="adminHome">
      <section className="adminHome__warning">
        <p className="adminHome__disclose">
          It looks like you are using a mobile device. Although you can use all
          admin features, your experience will be much better when viewing on a
          computer or tablet.
        </p>
      </section>
      <section className="adminHome__left">
        <Link to={"sermons"} className="adminHome__card adminHome__card--top">
          <section className="adminHome__text">
            <h3 className="adminHome__manage">Manage</h3>
            <h2 className="adminHome__label">Sermons</h2>
          </section>
          <section className="adminHome__image-wrapper">
            <img
              src={bibleOpen}
              alt="An open bible"
              className="adminHome__image"
            />
          </section>
        </Link>
        <Link to={"slides"} className="adminHome__card adminHome__card--bottom">
          <section className="adminHome__text">
            <h3 className="adminHome__manage">Manage</h3>
            <h2 className="adminHome__label">Slides</h2>
          </section>
          <section className="adminHome__image-wrapper">
            <img
              src={cross}
              alt="A cross on a hill"
              className="adminHome__image"
            />
          </section>
        </Link>
      </section>
      <section className="adminHome__right">
        <h3 className="adminHome__how-to">How do I add remove or edit...</h3>
        <Link to={"help/#sermon"} className="adminHome__help-card">
          <p className="adminHome__help-text">A sermon?</p>
        </Link>
        <Link to={"help/#slides"} className="adminHome__help-card">
          <p className="adminHome__help-text">A slide?</p>
        </Link>
      </section>
    </div>
  );
}
