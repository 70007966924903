import sundaySchool from "../../assets/images/sundaySchool.jpeg";
import peanutButter from "../../assets/images/peanutButter.jpg";
import womanBible from "../../assets/images/womanBible.jpg";
import kidCraft from "../../assets/images/kidCraft.jpg";
import womanGroup from "../../assets/images/womenGroup.jpg";
import benevolence from "../../assets/images/benevolence.jpg";
import surrenderSecret from "../../assets/images/surrenderCover.jpg";

export interface Ministry {
  title: string;
  desc: string;
  imgSrc: string;
  imgDesc: string;
  extraClass: string;
}

export const MinistryContent: Ministry[] = [
  {
    title: "Sunday School/Education",
    desc: `Christ not only commanded us to spread the gospel. He specifically commanded us to make disciples and teach everything He commanded. Our Sunday School/Education hour on Sunday mornings is a critical complement to our morning worship service. It is an opportunity to gather in small group settings for instruction, admonition, encouragement, and fellowship. Our youth classes are based more on age, while our adult classes are more integrated and not age based. Our ministry primarily uses the award-winning D6 Curriculum produced by D6 Family Ministry. Among the many strengths of this curriculum is its way of connecting parents to youth in meaningful dialogue and discussion about the same Scriptural texts and themes each week. Practically then, this means parents are prepared to have productive, spiritually significant conversations with their children throughout each week because they are learning the same spiritual truths in their different Sunday School classes.
    \n\n We also offer courses such as Grace 101-201 that help newcomers assimilate into the church and the Christian faith in general. Come and join us on Sunday mornings at 9:30 to learn more!`,
    imgSrc: sundaySchool,
    imgDesc: "A church classroom",
    extraClass: "ministryCard ministryCard--school",
  },
  {
    title: "Growth Groups",
    desc: `Growth Groups are a wonderful way to connect, share, and grow as part of the body of Christ. Every other month, several families will open their homes on a Sunday evening to host fellow members and newcomers. The groups eat, catch up, pray, and discuss how to apply recent sermons or lessons to their life. The meetings typically last two hours but have been known to last longer! If you're interested in learning more about attending a Growth Group, or being a host or facilitator, please ask the Pastor.`,
    imgSrc: womanBible,
    imgDesc: "A woman reading a Bible",
    extraClass: "ministryCard ministryCard--growth",
  },
  {
    title: "Children & Youth",
    desc: "Psalm 127 is so true when it says children are a heritage of the Lord. Our members strongly believe that. We LOVE the sounds of children in our halls and services. In addition to a caring Nursery ministry, we have classes for youth up to age 18 on Sundays. Throughout the year we have other opportunities for Bible learning and intergenerational fellowship, including youth camp, the church picnic, the family Christmas party, and more. Our Women's Ministry also 'adopts' prayer partners each year, which means that each child is especially prayed for and doted on throughout the year.",
    imgSrc: kidCraft,
    imgDesc: "A child making crafts",
    extraClass: "ministryCard ministryCard--youth",
  },
  {
    title: "Men & Women",
    desc: "On the first Wednesday night of each month, men and woman gather in their respective groups for prayer and study, and occasional service projects. These groups have a long track record of raising funds for worthy causes, helping widows or other members in need, and enlisting the entire church in specific projects. We believe God made men and women as distinct but complementary beings, and so these gatherings give space for us to recognize that distinctness through our conversations and fellowship.",
    imgSrc: womanGroup,
    imgDesc: "Women praying together",
    extraClass: "ministryCard ministryCard--menWomen",
  },
  {
    title: "Benevolence",
    desc: "We recognize that our brothers and sisters, as well as neighbors in the Arnold community, have many needs. Therefore, we are committed to trying to provide assistance whenever we are able.  Our Deacons oversee this ministry as we first seek to meet the needs of our own members (Gal. 6:10), but then we seek to show kindness and hospitality to those outside the body, much like the Good Samaritan who showed himself to be a good neighbor. Anyone who has a specific financial need is welcome to contact the church office to place a benevolent request. We will do what we reasonably can to encourage and help.",
    imgSrc: benevolence,
    imgDesc: "One person handing money to another person",
    extraClass: "ministryCard ministryCard--benevolence",
  },
  {
    title: "Food Bank",
    desc: "Grace maintains a small but helpful supply of non-perishable food items for needy members, as well as newcomers who may stop by and just need some help during a tight financial season. Grace is partnered with Arnold Food Pantry to meet more comprehensive dietary needs. However, we stand ready to be the first stop on people's journey to food security. The in-house food bank exists under the broader ministry of benevolence.",
    imgSrc: peanutButter,
    imgDesc: "A shelf stocked with non-perishable foods",
    extraClass: "ministryCard ministryCard--food",
  },
  {
    title: "Post-Abortive Care",
    desc: `"Surrendering the Secret" is a powerful, redemptive study that helps women find a personal path to healing the heartbreak of abortion. This is an 8-week Bible study that enables women to release this burden and find freedom through an honest interactive study. This is private and confidential, and is sponsored by our ministry partner, Hand 'n Hand Women's Center. Contact Tiffany at 314-313-1794 for more information.`,
    imgSrc: surrenderSecret,
    imgDesc: "Surrendering the Secret logo",
    extraClass: "ministryCard ministryCard--care",
  },
];
