import { Link } from "react-router-dom";
import ContactForm from "../../components/ContactForm/ContactForm";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./ContactPage.scss";
import React from "react";

export default function ContactPage() {
  return (
    <div className="contactPage">
      <HeroSmall content="contact us" classtitle="heroSmall--contact" />
      <section className="contactPage__wrapper">
        <div className="contactPage__content">
          <h2 className="contactPage__header">Contact Us</h2>
          <section className="contactPage__text">
            <p className="contactPage__phrase">
              Whether you have questions, a prayer request, or just want to say
              hi, we would love to hear from you!
            </p>
            <section className="contactPage__phone">
              <i className="fa-solid fa-phone fa-lg"></i>
              <a href="tel:636-296-2380" className="contactPage__number">
                636-296-2380
              </a>
            </section>
          </section>
          <section className="contactPage__socials">
            <p className="socials__header">Connect with us online!</p>
            <Link
              className="socials__social"
              to="https://www.youtube.com/@moreofgrace_arnold"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-youtube fa-xl"></i>
              <p className="socials__text">youtube.com/@moreofGrace_arnold</p>
            </Link>

            <Link
              className="socials__social"
              to="https://www.facebook.com/moreofgrace"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-facebook fa-xl"></i>
              <p className="socials__text">facebook.com/moreofgrace</p>
            </Link>
          </section>
        </div>
        <ContactForm classTitle="contact" />
      </section>
    </div>
  );
}
