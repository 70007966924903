import "./AdminFoot.scss";
import whiteGraceLogo from "../../assets/images/whiteGraceFull.png";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

export default function AdminFoot() {
  return (
    <div className="footer footer--admin">
      <section className="footer__foot">
        <div className="foot__wrapper">
          <section className="foot__logo">
            <img src={whiteGraceLogo} alt="" className="foot__img" />
            <p className="foot__copyright">
              © Copyright 2024 Grace free will Baptist church All rights
              reserved
            </p>
          </section>
          <section className="foot__times">
            <p className="foot__title">Weekly Gatherings</p>
            <ul className="foot__list">
              <li className="foot__item">
                <p className="foot__service">Sunday School:</p>
                <p className="foot__time">9:30 AM</p>
              </li>
              <li className="foot__item">
                <p className="foot__service">Sunday Worship:</p>
                <p className="foot__time">10:30 AM</p>
              </li>
              <li className="foot__item">
                <p className="foot__service">Midweek Study/Prayer:</p>
                <p className="foot__time">10:00 AM</p>
              </li>
              <li className="foot__item">
                <p className="foot__service">Online (Contact Church Office):</p>
                <p className="foot__time">7:00 PM</p>
              </li>
            </ul>
          </section>
          <section className="foot__links">
            <p className="foot__title">Quicklinks</p>
            <Link to={"/"} className="foot__link">
              Home
            </Link>
            <Link to={"/plan"} className="foot__link">
              I'm new
            </Link>
            <Link to={"/about"} className="foot__link">
              About Us
            </Link>
            <Link to={"/ministries"} className="foot__link">
              Ministries
            </Link>
            <Link to={"/sermons"} className="foot__link">
              Watch
            </Link>
            <Link to={"/give"} className="foot__link">
              Give
            </Link>
          </section>
          <section className="foot__contact">
            <Link to={"/contact"} className="foot__contact--link">
              <Button content="contact us" classtitle="button button--footer" />
            </Link>
          </section>
        </div>
      </section>
    </div>
  );
}
