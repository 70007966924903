import React, { useEffect, useState } from "react";
import "./SermonsPage.scss";
import SermonCard from "../../components/SermonCard/SermonCard";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import { videoSermon } from "../../types/sermonInterface";
import axios from "axios";
import RecentSermon from "../../components/RecentSermon/RecentSermon";
import groupAndSortSermons from "./SermonUtils";

export default function SermonsPage() {
  const [recentSermon, setRecentSermon] = useState<videoSermon | undefined>(
    undefined
  );
  const [hiddenSermons, setHiddenSermons] = useState<videoSermon[]>([]);
  const [sermonList, setSermonList] = useState<videoSermon[]>([]);
  const [groupedSermonList, setGroupedSermonList] = useState<{
    [key: string]: videoSermon[];
  }>({});
  const [sortOrder, setSortOrder] = useState<
    | "default"
    | "date-ascend"
    | "date-descend"
    | "category"
    | "title-ascend"
    | "title-descend"
  >("default");
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    async function getSermonList() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/sermon/list`
        );

        if (data.length > 0) {
          const sortedData = data.sort((a: videoSermon, b: videoSermon) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            return dateB.getTime() - dateA.getTime();
          });

          const [firstSermon, ...restSermons] = sortedData;
          setRecentSermon(firstSermon);
          setSermonList(restSermons.slice(0, 9));
          setHiddenSermons(restSermons.slice(9));
        } else {
          return;
        }
      } catch (error) {
        console.error("Failed to fetch sermon list:", error);
      }
    }

    getSermonList();
  }, []);

  const mergeSermon = () => {
    if (!recentSermon) {
      return;
    }
    if (!hiddenSermons) {
      return;
    }

    const mergedList: videoSermon[] = [
      ...sermonList,
      recentSermon,
      ...hiddenSermons,
    ];

    setRecentSermon(undefined);
    setHiddenSermons([]);
    setSermonList(mergedList);
    return;
  };

  const sortSermons = (sermons: videoSermon[]): videoSermon[] => {
    switch (sortOrder) {
      case "date-descend":
        return sermons.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      case "date-ascend":
        return sermons.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      case "title-descend":
        return sermons.sort((a, b) => a.title.localeCompare(b.title));
      case "title-ascend":
        return sermons.sort((a, b) => b.title.localeCompare(a.title));
      default:
        return sermons;
    }
  };

  const filterSermons = (): videoSermon[] => {
    if (sortOrder === "default") {
      setSortOrder("date-descend");
    }

    mergeSermon();

    return sermonList.filter((sermon) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return (
        sermon.title.toLowerCase().includes(lowerCaseSearchQuery) ||
        sermon.desc.toLowerCase().includes(lowerCaseSearchQuery)
      );
    });
  };

  const displayedSermons = searchQuery ? filterSermons() : sermonList;

  useEffect(() => {
    mergeSermon();

    if (sortOrder === "category") {
      const groupedAndSortedSermons = groupAndSortSermons([...sermonList]);
      setGroupedSermonList(groupedAndSortedSermons);
    } else {
      setSermonList((prevList) => sortSermons([...prevList]));
    }
    // eslint-disable-next-line
  }, [sortOrder]);

  return (
    <div className="sermonsPage">
      <HeroSmall content="sermons" classtitle="heroSmall--sermons" />
      <section
        className={
          sortOrder === "default"
            ? "sermonsPage__labelSearch"
            : "sermonsPage__labelSearch sermonsPage__labelSearch--alt"
        }
      >
        {sortOrder === "default" && (
          <h5 className="sermonsPage__label">most recent</h5>
        )}
        <select
          name="sort"
          id="sort-select"
          onChange={(e) =>
            setSortOrder(
              e.target.value as
                | "default"
                | "date-descend"
                | "date-ascend"
                | "category"
                | "title-descend"
                | "title-ascend"
            )
          }
          value={sortOrder}
          className="sermonsPage__select"
        >
          <option value="default" disabled>
            Sort by...
          </option>
          <option value="date-descend">Most Recent</option>
          <option value="date-ascend">Oldest</option>
          <option value="category">Category</option>
          <option value="title-descend">Title A-Z</option>
          <option value="title-ascend">Title Z-A</option>
        </select>
        <form className="sermonsPage__searchWrapper">
          <input
            type="search"
            name="listSearch"
            id="listSearch"
            className="sermonsPage__search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <i className="fa-solid fa-magnifying-glass fa-sm"></i>
        </form>
      </section>
      {recentSermon && <RecentSermon recentSermon={recentSermon} />}
      <section className="sermonsPage__list">
        {sortOrder === "category"
          ? Object.entries(groupedSermonList)
              .sort(([keyA], [keyB]) => {
                if (keyA === "Misc.") return 1;
                if (keyB === "Misc.") return -1;
                return keyA.localeCompare(keyB);
              })
              .map(([category, sermons]) => (
                <div key={category} className="sermonsPage__categoryWrapper">
                  <h3 className="sermonsPage__category">{category}</h3>
                  {sermons.map((sermon, i) => (
                    <SermonCard sermon={sermon} key={i} />
                  ))}
                </div>
              ))
          : displayedSermons.map((sermon, i) => (
              <SermonCard sermon={sermon} key={i} />
            ))}
      </section>
      <section className="sermonsPage__viewAll">
        <div
          className="viewAll__wrapper"
          onClick={() => {
            setSearchQuery(" ");
            window.scrollTo({ top: 0 });
          }}
        >
          <p className="viewAll__text">View All</p>
          <i className="fa-solid fa-chevron-right" />
        </div>
      </section>
    </div>
  );
}
