import Button from "../Button/Button";
import "./Navbar.scss";
import graceLogo from "../../assets/images/graceBanner.png";
import { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { AboutItems } from "../Dropdown/AboutItems";
import { Link } from "react-router-dom";
import { WatchItems } from "../Dropdown/WatchItems";
import closeMenu from "../../assets/svg/x.svg";
import openMenu from "../../assets/svg/menu.svg";

export default function Navbar() {
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [watchDropdown, setWatchDropdown] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const onMouseEnterAbout = () => {
    setAboutDropdown(true);
  };
  const onMouseLeaveAbout = () => {
    setAboutDropdown(false);
  };

  const onMouseEnterWatch = () => {
    setWatchDropdown(true);
  };
  const onMouseLeaveWatch = () => {
    setWatchDropdown(false);
  };

  function changeMenu() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className="navbar">
      <Link
        to={"/"}
        className="logo__link"
        onClick={() => setMobileOpen(false)}
      >
        <img
          src={graceLogo}
          alt="Grace Free Will Baptist Logo"
          className="navbar__logo"
        />
      </Link>
      <section onClick={changeMenu} className="navMobile__trigger">
        <img
          src={mobileOpen ? closeMenu : openMenu}
          alt={mobileOpen ? "Close Menu" : "Open Menu"}
          className="trigger__icon"
        />
      </section>
      <ul
        className={mobileOpen ? "navbar__list" : "navbar__list navbar__hidden"}
      >
        <li className="navbar__item">
          <Link to={"/"} className="navbar__link" onClick={changeMenu}>
            Home
          </Link>
        </li>
        <li
          className="navbar__item"
          onMouseEnter={onMouseEnterAbout}
          onMouseLeave={onMouseLeaveAbout}
        >
          <span>about</span>
          {aboutDropdown && (
            <Dropdown changeMenu={changeMenu} items={AboutItems} />
          )}
        </li>
        <li
          className="navbar__item"
          onMouseEnter={onMouseEnterWatch}
          onMouseLeave={onMouseLeaveWatch}
        >
          <span>watch</span>
          {watchDropdown && (
            <Dropdown changeMenu={changeMenu} items={WatchItems} />
          )}
        </li>
        <li className="navbar__item">
          <Link to={"/give"} className="navbar__link" onClick={changeMenu}>
            Give
          </Link>
        </li>
        <li className="navbar__item navbar__item--button" onClick={changeMenu}>
          <Link to={"/contact"} className="navbar__link">
            <Button content="contact us" classtitle="button button--navbar" />{" "}
          </Link>
        </li>
      </ul>
    </div>
  );
}
