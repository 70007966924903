import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./HistoryPage.scss";
import buildingHistory from "../../assets/images/buildingHistory.jpg";
import Timeline from "../../components/Timeline/Timeline";

export default function HistoryPage() {
  return (
    <div className="historyPage">
      <HeroSmall
        classtitle="heroSmall--history"
        content="Our story & location"
      />
      <p className="historyPage__paragraph">
        Grace Free Will Baptist Church began as a dream in a tent meeting that
        happened near the Rockport Elementary School in the late 1950s. Later,
        an old store building located at 1524 Jeffco Boulevard was rented and
        renovated to serve as the first church. A local chiropractor owned the
        building and maintained a small office on the north side of it, while
        the rest of the building and a small room in the basement was rented.
        One church member paid the rent for 6 months in advance while other
        congregations and the Mission Board provided chairs, hymnals, and other
        resources to facilitate the church’s worship and ministry.
      </p>
      <img
        src={buildingHistory}
        alt="Pencil-drawn church building"
        className="historyPage__img"
      />
      <p className="historyPage__paragraph">
        The official organizational meeting was conducted on July 28, 1961.
        Twenty-two people were present, with Reverend Johnnie Land serving as
        pastor.
      </p>
      <p className="historyPage__paragraph">
        Fifty-two cents were found while cleaning the old store building. This
        money was placed on the altar during the first service, and became the
        genesis of the building fund. The Lord has since multiplied this many
        times over. Within a few months, the new church needed more space. Bonds
        were sold and four lots at the corner of Leolia and Tenbrook Road were
        purchased. A building program was begun in the spring of 1962, and
        completed that October. A dedication service was conducted in the new
        building on October 21st. A three-bedroom ranch style home was added
        shortly thereafter to serve as the parsonage.
      </p>
      <p className="historyPage__paragraph">
        Following Reverend Land’s resignation in the fall of 1967, Reverend
        Glenn Rehkop, previously of Kansas City, accepted the pastorate. He
        began his ministry on December 17 of that year after Brother Bob Wilfong
        had served as the interim preacher. The ministry continued to grow such
        that the sanctuary was expanded significantly and necessary classroom
        space was added. Another building program was started in October 1977 to
        meet the church’s needs. The sanctuary was enlarged to seat up to 500,
        and other additions, including a new fellowship hall, were made. In
        1996, the church purchased the lot behind the church property.
      </p>
      <p className="historyPage__paragraph">
        Pastor Glenn Rehkop retired in March 2008 after nearly 41 years of
        service. He continued to preach until Reverend Brent Hutsell arrived in
        December, along with his wife and three children. The Hutsells served
        until May 2011 when they departed to serve Summerton Baptist Church in
        South Carolina.
      </p>
      <p className="historyPage__paragraph">
        In August 2011, Reverend Jackson Watts was called to be the fourth
        pastor in Grace’s 50th year of ministry. He moved to Grace after having
        ministered for several years in North Carolina.
      </p>
      <p className="historyPage__paragraph">
        The Lord has continued to be faithful by blessing our church in so many
        ways. We look forward to many more years of fruitful ministry, laboring
        for Christ until He returns.
      </p>
      <Timeline />
    </div>
  );
}
