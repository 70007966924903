export const AboutItems = [
  {
    title: "Who we are",
    path: "/about",
    cName: "dropdown__link",
  },
  {
    title: "Our History & Location",
    path: "/history",
    cName: "dropdown__link",
  },
  {
    title: "Our Beliefs & Values",
    path: "/beliefs",
    cName: "dropdown__link",
  },
  {
    title: "Our Leadership & Staff",
    path: "/leadership",
    cName: "dropdown__link",
  },
  {
    title: "Our Affiliations & Partners",
    path: "/partners",
    cName: "dropdown__link",
  },
];
