import React, { useEffect, useState } from "react";
import "./VideoPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import cross from "../../assets/images/cross.jpg";
import { videoSermon } from "../../types/sermonInterface";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import axios from "axios";

export default function VideoPage() {
  const [currentSermon, setCurrentSermon] = useState<videoSermon | undefined>(
    undefined
  );

  const [sermonAudio, setSermonAudio] = useState<string | undefined>(undefined);

  const { sermonId } = useParams<{ sermonId: string }>();

  useEffect(() => {
    async function getSermon() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/sermon/${sermonId}`
        );

        if (data) {
          setCurrentSermon(data);
        } else {
          return;
        }
      } catch (error) {
        console.error("Failed to fetch sermon list:", error);
      }
    }

    getSermon();
  }, [sermonId]);

  useEffect(() => {
    async function getAudio() {
      try {
        const { data } = await axios.get(`${currentSermon?.audioFile}`);

        if (data) {
          setSermonAudio(data);
        } else {
          return;
        }
      } catch (error) {
        console.error("Failed to fetch sermon audio:", error);
      }
    }

    getAudio();
  }, [currentSermon]);

  const navigate = useNavigate();

  //this goes in the form Zea
  function extractId(
    input: string | undefined,
    time: string | undefined
  ): string {
    if (!input) {
      return "null";
    }
    const regex = /(?:v=|\/v\/|be\/|embed\/|watch\?v=|watch\?.+&v=)([^&\s]+)/;
    const videoID = input.match(regex);

    if (videoID && videoID.length > 1) {
      return `https://www.youtube.com/embed/${videoID[1]}?start=${time}`;
    } else {
      return "null";
    }
  }

  if (!currentSermon?.videoLink && !currentSermon?.audioFile) {
    return (
      <div className="videoPageError">
        <HeroSmall content="" classtitle="heroSmall--videoError" />
        <h1 className="videoPageError__header">
          Looks like something is missing here!
        </h1>
        <p className="videoPageError__text">
          Sorry for the hassle. Something unexpected happened. We'll fix this as
          soon as possible. Until then, we would love to invite you to come
          visit us in person for worship!
        </p>
      </div>
    );
  } else if (!currentSermon?.videoLink && currentSermon?.audioFile) {
    return (
      <div className="videoPage">
        <section className="videoPage__hero">
          <img
            src={currentSermon?.image ?? cross}
            alt="Sermon Series Banner"
            className="videoPage__img"
          />
        </section>
        <section onClick={() => navigate(-1)} className="videoPage__back">
          <div className="back__wrapper">
            <i className="fa-solid fa-chevron-left" />
            <p className="back__text">Back</p>
          </div>
        </section>
        <section className="videoPage__audioWrapper">
          <audio controls src={sermonAudio} className="videoPage__audio">
            Your browser does not support audio elements.
          </audio>
        </section>
        <section className="videoPage__content">
          <div className="videoPage__sermonInfo">
            <h4 className="videoPage__title">{currentSermon?.title}</h4>
            <p className="videoPage__ref">{currentSermon?.ref}</p>
          </div>
          <div className="videoPage__speakDate">
            <h4 className="videoPage__speaker">{currentSermon?.speaker}</h4>
            <p className="videoPage__date videoPage__date--desktop">
              {new Date(currentSermon?.date + "T00:00:00").toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </p>
          </div>
          <p className="videoPage__desc">{currentSermon?.desc}</p>
          <div className="videoPage__dateDown">
            <p className="videoPage__date videoPage__date--mobile">
              {new Date(currentSermon?.date + "T00:00:00").toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </p>
            <a href={sermonAudio} className="videoPage__download">
              download audio
            </a>
          </div>
        </section>
      </div>
    );
  }
  return (
    <div className="videoPage">
      <section className="videoPage__hero">
        <img
          src={currentSermon?.image ?? cross}
          alt="Sermon Series Banner"
          className="videoPage__img"
        />
      </section>
      <section className="videoPage__back">
        <div className="back__wrapper" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-chevron-left" />
          <p className="back__text">Back</p>
        </div>
      </section>
      <section className="videoPage__videoWrapper">
        <iframe
          width="100%"
          height="100%"
          src={extractId(currentSermon?.videoLink, currentSermon?.timestamp)}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </section>
      <section className="videoPage__content">
        <div className="videoPage__sermonInfo">
          <h4 className="videoPage__title">{currentSermon?.title}</h4>
          <p className="videoPage__ref">{currentSermon?.ref}</p>
        </div>
        <div className="videoPage__speakDate">
          <h4 className="videoPage__speaker">{currentSermon?.speaker}</h4>
          <p className="videoPage__date videoPage__date--desktop">
            {new Date(currentSermon?.date + "T00:00:00").toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          </p>
        </div>
        <p className="videoPage__desc">{currentSermon?.desc}</p>
        <div className="videoPage__dateDown">
          <p className="videoPage__date videoPage__date--mobile">
            {new Date(currentSermon?.date + "T00:00:00").toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          </p>
          {sermonAudio ? (
            <a href={sermonAudio} className="videoPage__download">
              download audio
            </a>
          ) : (
            <></>
          )}
        </div>
      </section>
    </div>
  );
}
