import React from "react";
import "./RecentSermon.scss";
import { Link } from "react-router-dom";
import { videoSermon } from "../../types/sermonInterface";
import cross from "../../assets/images/cross.jpg";

interface RecentSermonProps {
  recentSermon: videoSermon;
}

export default function RecentSermon({ recentSermon }: RecentSermonProps) {
  return (
    <Link
      to={`/sermon/${recentSermon?.videoId}`}
      className="sermonsPage__recent"
    >
      <div className="recent__wrapper">
        <img
          src={recentSermon?.image ? recentSermon.image : cross}
          alt="Recent Sermon Series"
          className="recent__img"
        />
      </div>
      <div className="recent__content">
        <h5 className="recent__title">{recentSermon?.title}</h5>
        <p className="recent__reference">{recentSermon?.ref}</p>
        <p className="recent__desc">{recentSermon?.desc}</p>
        <p className="recent__date">
          {new Date(recentSermon?.date + "T00:00:00").toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          )}
        </p>
      </div>
    </Link>
  );
}
