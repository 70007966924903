import React from "react";
import "./PartnerCard.scss";

export default function PartnerCard(props: {
  title: string;
  desc: string;
  imgSrc: string;
  imgDesc: string;
  extraClass: string;
}) {
  return (
    <div className={props.extraClass}>
      <section className="partnerCard__wrapper">
        <img
          src={props.imgSrc}
          alt={props.imgDesc}
          className="partnerCard__img"
        />
      </section>
      <section className="partnerCard__content">
        <h3 className="partnerCard__title">{props.title}</h3>
        <p className="partnerCard__desc">{props.desc}</p>
      </section>
    </div>
  );
}
