import "./Dropdown.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

interface DropdownItems {
  title: string;
  path: string;
  cName: string;
  external?: boolean;
}

export default function Dropdown(props: {
  items: DropdownItems[];
  changeMenu: () => void;
}) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <ul
      onClick={handleClick}
      className={click ? "dropdown dropdown--hidden" : "dropdown"}
    >
      {props.items.map((item, index) => {
        if (item.external) {
          return (
            <li key={index} className="dropdown__item">
              <Link
                className={item.cName}
                to={item.path}
                onClick={props.changeMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </Link>
            </li>
          );
        }
        return (
          <li key={index} className="dropdown__item">
            <Link
              className={item.cName}
              to={item.path}
              onClick={props.changeMenu}
            >
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
