import React from "react";
import "./ContactForm.scss";
import Button from "../Button/Button";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";

export default function ContactForm(props: { classTitle: string }) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      formName: "",
      formEmail: "",
      formSubject: "",
      formMessage: "",
    },
  });

  const submitHandler = (content: object) => {
    async function sendEmail() {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/email/new`, content);
    }

    toast
      .promise(
        sendEmail(),
        {
          loading: "Sending message...",
          success: "Message sent! We will reach out to you soon.",
          error: "Failed to send message.",
        },
        { duration: 4750, className: "toast" }
      )
      .then(() => {
        reset();
      })
      .catch((error) => {
        console.error("Failed to send message:", error);
      });
  };

  return (
    <div className={`contactForm contactForm--${props.classTitle}`}>
      <form
        onSubmit={handleSubmit((data) => submitHandler(data))}
        className="contactForm__form"
      >
        <input
          {...register("formName")}
          placeholder="Your Full Name"
          type="text"
          className="contactForm__name"
          required
        />
        <input
          {...register("formEmail")}
          placeholder="Your Email"
          type="email"
          className="contactForm__email"
          required
        />
        <input
          {...register("formSubject")}
          placeholder="Subject"
          type="text"
          className="contactForm__subject"
          required
        />
        <textarea
          {...register("formMessage")}
          id="formMessage"
          placeholder="Message"
          className="contactForm__message"
          required
        ></textarea>
        <div className="contactForm__wrapper">
          <section className="contactForm__recaptcha"></section>
          <Button classtitle="button button--contact" content="Send Message" />
        </div>
      </form>
    </div>
  );
}
