import { videoSermon } from "../../types/sermonInterface";

const groupAndSortSermons = (
  sermons: videoSermon[]
): { [key: string]: videoSermon[] } => {
  const groupedSermons: { [key: string]: videoSermon[] } = {};

  sermons.forEach((sermon) => {
    const category = sermon.category || "Misc.";

    if (!groupedSermons[category]) {
      groupedSermons[category] = [];
    }

    groupedSermons[category].push(sermon);
  });

  for (const category in groupedSermons) {
    groupedSermons[category].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  return groupedSermons;
};

export default groupAndSortSermons;
