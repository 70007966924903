import MinistryCard from "../../components/MinistryCard/MinistryCard";
import {
  MinistryContent,
  Ministry,
} from "../../components/MinistryCard/MinistryContents";
import HeroSmall from "../../components/HeroSmall/HeroSmall";

export default function MinistriesPage() {
  return (
    <div className="MinistryPage">
      <HeroSmall classtitle="heroSmall--ministry" content="Our Ministries" />
      {MinistryContent.map((ministry: Ministry, index: number) => (
        <MinistryCard
          key={index}
          title={ministry.title}
          desc={ministry.desc}
          imgSrc={ministry.imgSrc}
          imgDesc={ministry.imgDesc}
          extraClass={ministry.extraClass}
          isAlternate={index % 2 === 0}
        />
      ))}
    </div>
  );
}
