export interface Belief {
  title: string;
  desc: string;
}

export const BeliefsContent: Belief[] = [
  {
    title: "Who God Is",
    desc: "We believe in one God, eternally existing in three persons—Father, Son, and Holy Spirit—each co-eternal in being, co-identical in nature, coequal in power and glory, and having the same attributes: eternal, all-powerful, all-knowing, omnipresent, holy, loving, just, merciful, and unchanging. He has given us a partial knowledge of who He is through His creation and our consciences, but a fuller knowledge through His Word and Christ the Son.",
  },
  {
    title: "God's World",
    desc: "We believe that God created the universe out of nothing through the spoken word. The Genesis account of creation refers to true events in history, even as it uses unique literary forms. The entirety of creation, including humanity, has God as its origin. God’s creation was perfect, and despite the profound effects of sin upon it after human beings sinned, it is to be received and treated as a gift from God. It is an enduring expression of God's love and care.",
  },
  {
    title: "Our Condition",
    desc: "We believe that man was created in the image and likeness of God. Humans are inherently valuable and are distinct from other creatures because of our ability to relate to God in a moral relationship and as rational beings. However, when Adam and Eve sinned, the human race fell, inheriting a sinful nature, and became alienated from God. Man is totally depraved and, of himself, utterly unable to resolve his lost condition. We experience the brokenness of sin in numerous ways, and therefore, stand in need of a Redeemer.",
  },
  {
    title: "God's Word",
    desc: "We believe that God has graciously has provided a special, written revelation to fallen mankind in the form of the Bible. We believe the Holy Scriptures of the Old and New Testament are the complete, verbally inspired Word of God. The Scriptures are inerrant and infallible and therefore, the final authority for faith and life. The sixty-six books of the Old and New Testament are the complete and divine revelation of God to Man.",
  },
  {
    title: "God the Son",
    desc: "We believe that the Lord Jesus Christ, the eternal Son of God, became man, without ceasing to be God, having been conceived by the Holy Spirit and born of the virgin Mary, in order that He might reveal God and redeem sinful men. We deserved punishment and separation from God because of our sins. Yet Jesus, who lived a perfect life, took our sins upon Himself by dying on the cross and rising from the dead. God the Father accepted His sacrifice on our behalf, and we are now justified (accepted by God) by faith because of Jesus’s perfect life, sacrificial death, and bodily resurrection. We believe that the Lord Jesus Christ ascended to Heaven and is now exalted at the right hand of God, where, as our High Priest, He intercedes on our behalf.",
  },
  {
    title: "God the Spirit",
    desc: "We believe that the Holy Spirit convicts the world of sin, of righteousness, and of judgment. He is the source of regeneration, baptizing all believers into the body of Christ, indwelling and sealing them unto the day of Judgment. We believe that the Spirit of God assists believers to understand and apply the Scriptures and that it is the privilege and duty of all the saved to be filled with the Spirit. We believe that God is sovereign in giving spiritual gifts to every believer. God uniquely uses evangelists, pastors, and teachers to equip believers to carry out the ministry. We believe that the sign gifts of the Holy Spirit, such as speaking in tongues and the gift of healing, were temporary. Speaking in tongues was never the common or necessary sign of the baptism or filling of the Holy Spirit and ultimate deliverance of the body from sickness or death awaits our future resurrection, though God frequently chooses to answer the prayers of believers for physical healing.",
  },
  {
    title: "God's Salvation",
    desc: "We believe that salvation is the gift of God brought to man by grace and received by personal faith in the Lord Jesus Christ, whose precious blood was shed on Calvary for the forgiveness of our sins. We believe that those who would comply with the condition of salvation (faith) constitute His elect, and that God has given to man the freedom of choice permitting him to yield to the influence of the Holy Spirit or to resist and perish. We believe that God desires the salvation of all people everywhere.",
  },
  {
    title: "The Christian Life",
    desc: "We believe that every believer is cleansed and morally set apart in Christ at conversion; that this setting apart (“sanctifying”) continues during the Christian life as the believer grows in the grace and knowledge of Jesus (not sinless perfection); and that it is completed when the believer stands in the presence of Christ, either at death or at the coming of Christ for His Church. We believe God enables the redeemed to persevere in their faith, yet permits believers to maintain the free will to blaspheme the Holy Spirit, commit apostasy, and become forever lost. We are called to be increasingly conformed to the likeness of Jesus Christ, and to reflect His image in the world through godly character, joyful obedience, and sacrificial service.",
  },
  {
    title: "God's Church",
    desc: "We believe that the local church, which is the body and bride of Christ, is comprised of born-again persons. We believe in the autonomy of the local church free of any external authority or control, though it may unite with other like-minded church bodies for mutual accountability, fellowship, and collective ministry efforts. Elders or pastors and deacons are the two biblical offices of the church. We recognize water baptism (by immersion, for believers only), the Lord’s Supper (which is to be open to all born-again believers of like faith), and Feet Washing, as the Scriptural ordinances of obedience for the church.",
  },
  {
    title: "Our Future",
    desc: "We believe in that “blessed hope,” the personal, imminent, glorious return of Jesus Christ. We believe in the bodily resurrection of all men, the saved to eternal life, and the unsaved to judgment and everlasting punishment. It is at this point that God will institute a new heavens and a new earth in which the redeemed will live and reign with Christ. Until then, Christians are called to orient their lives around Christ's Kingdom. We are to live in light of what Jesus has done, and in light of the fact that He will return.",
  },
];
