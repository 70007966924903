import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./BeliefsPage.scss";
import { BeliefsContent } from "./BeliefsContent";
import valuesChart from "../../assets/images/values.png";
import valuesMobile from "../../assets/images/valuesMobile.png";

export default function BeliefsPage() {
  return (
    <div className="beliefValue">
      <HeroSmall
        content="our beliefs & values"
        classtitle="heroSmall--beliefs"
      />
      <section className="beliefValue__beliefs">
        <h2 className="beliefs__header">Our Beliefs</h2>
        <ul className="beliefs__list">
          {BeliefsContent.map((belief, index) => (
            <li className="beliefs__belief" id={`belief--${index}`}>
              <h3 className="belief__title">{belief.title}</h3>
              <p className="belief__desc">{belief.desc}</p>
            </li>
          ))}
        </ul>
        <p className="beliefs__moreInfo">
          For a more detailed look at our church’s official profession of faith,
          click{" "}
          <a
            className="beliefs__link"
            href="https://nafwb.org/site/wp-content/uploads/2023/05/2023-NAFWB-Treatise.pdf"
          >
            here
          </a>
          .
        </p>
      </section>
      <section className="beliefValue__values">
        <h2 className="values__header">Our Values</h2>
        <div className="values__container">
          <img
            src={valuesChart}
            alt="Our 4 core values, gather grow give and go."
            className="values__img"
          />
          <img
            src={valuesMobile}
            alt="Our 4 core values, gather grow give and go."
            className="values__img values__img--mobile"
          />
        </div>
        <p className="values__caption">
          The four core values below are important to us not as a matter of
          preference, but because we believe Jesus Himself desires for us to be
          faithful in these core ways and shows us how in Scripture! While we do
          not always reflect them as fully as we should, we strive as a
          congregation to reflect them in our lives individually, but especially
          in our life together as a body.
        </p>
        <div className="values__wrapper">
          <section className="values__value">
            <h3 className="values__title">Gather</h3>
            <p className="values__desc">
              We value coming together regularly on the Lord’s Day for worship,
              and as often as possible for other times of fellowship, ministry,
              and instruction.
            </p>
            <p className="values__tag">We aim to gather together regularly.</p>
          </section>
          <section className="values__value">
            <h3 className="values__title">Grow</h3>
            <p className="values__desc">
              We value opportunities for spiritual growth, made possible first
              by God Himself, but specifically by means of His Word and Spirit
              working through His people. We also recognize that God uses any
              and all life situations—blessings and sufferings—to make us into
              the people He wants us to be.
            </p>
            <p className="values__tag">
              We aim to grow together intentionally.
            </p>
          </section>
          <section className="values__value">
            <h3 className="values__title">Give</h3>
            <p className="values__desc">
              We value the opportunity to give back to God’s Kingdom through
              ministry and service. We give in many ways: volunteering our time,
              using our talents, giving our money and possessions, and generally
              viewing everything we have and everything we are as a gift from
              God. Therefore, we believe the posture of a grateful person for
              whom everything in life is a gift is generosity and service.
              Service is ministry, and ministry involves giving.
            </p>
            <p className="values__tag">
              We aim to give together sacrificially.
            </p>
          </section>
          <section className="values__value">
            <h3 className="values__title">Go</h3>
            <p className="values__desc">
              We value God’s call to be witnesses as we go through the world.
              Whether it be international missions, domestic missions, or
              personal evangelism, we want our commitments to these to be
              reflected in prayer, in giving, in partnerships, and in individual
              obedience. The earliest Christians understood that Christ was both
              gathering them together in community and scattering them
              providentially to fulfill His mission. We accept His missional
              design.
            </p>
            <p className="values__tag">We aim to go together boldly.</p>
          </section>
        </div>
      </section>
    </div>
  );
}
