export interface TimelineDate {
  date: string;
  desc: string;
}

export const TimelineContent: TimelineDate[] = [
  {
    date: "1950s",
    desc: "First tent meeting near Rockport Elementary.",
  },
  {
    date: "1960",
    desc: `First church building is renovated at 1524 Jeffco Boulevard.`,
  },
  {
    date: "1961",
    desc: "First official organizational meeting conducted and Reverend Johnnie Land is chosen as pastor of Grace Free Will Baptist Church.",
  },
  {
    date: "1962",
    desc: "New building is built on the corner of Leolia and Tenbrook Road. Dedication service is held on October 21st.",
  },
  {
    date: "1967",
    desc: "Reverend Johnnie Land resigns and Brother Bob Wilfong serves as interim pastor. In December Reverend Glenn Rehkop accepts the pastorate.",
  },
  {
    date: "1977",
    desc: "Due to continued growth, the church building is expanded with a larger sanctuary and a new fellowship hall.",
  },
  {
    date: "1996",
    desc: "The church acquires the lot behind the church property.",
  },
  {
    date: "2008",
    desc: "Reverend Glenn Rehkop retires and Reverend Brent Hutsell takes over as pastor.",
  },
  {
    date: "2011",
    desc: "Reverend Brent Hutsell steps down to serve in South Carolina. Reverend Jackson Watts becomes the new pastor.",
  },
  {
    date: "2011",
    desc: "The church celebrates 50 years of ministry.",
  },
  {
    date: "2021",
    desc: "The church celebrates 60 years of ministry.",
  },
  {
    date: "PRESENT",
    desc: "God continues to bless our church as we seek to serve the Arnold community.",
  },
];
