import React from "react";
import "./Timeline.scss";
import { TimelineContent, TimelineDate } from "./TimelineContents";

export default function Timeline() {
  return (
    <div className="timeline">
      <hr className="timeline__main" />
      <ul className="timeline__list">
        {TimelineContent.map((tDate: TimelineDate, index: number) => (
          <li
            className={
              index % 2 === 0
                ? "timeline__entry timeline__entry--ALT"
                : "timeline__entry"
            }
            id={`tDate--${index}`}
          >
            <div className="timeline__branch">
              <div className="timeline__line"></div>
              <div className="timeline__node"></div>
            </div>
            <div className="timeline__text">
              <h3 className="timeline__date">{tDate.date}</h3>
              <p className="timeline__desc">{tDate.desc}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
