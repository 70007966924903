import React from "react";
import "./PartnersPage.scss";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import {
  PartnerContents,
  Partner,
} from "../../components/PartnerCard/PartnerContents";
import PartnerCard from "../../components/PartnerCard/PartnerCard";

export default function PartnersPage() {
  return (
    <div className="partnersAffiliation">
      <HeroSmall
        content="affiliations & partners"
        classtitle="heroSmall--partners"
      />
      <section className="partnersAffiliation__affiliations">
        <h2 className="affiliations__title">Our Affiliations</h2>
        <p className="affiliations__paragraph">
          Free Will Baptist churches, while autonomous in nature, are affiliated
          with other congregations. Grace Free Will Baptist Church is affiliated
          with the St. Louis District Association, the Missouri State
          Association, and the National Association of Free Will Baptists, Inc.
          For more about our denomination, see{" "}
          <a className="affiliations__link" href="http://nafwb.org/">
            http://nafwb.org
          </a>
        </p>
        <p className="affiliations__paragraph">
          By virtue of our affiliation with the National Association, we are
          supportive of collective efforts to advance the Gospel not only in our
          community, but throughout the world. Thus, we have partnerships with
          both{" "}
          <a className="affiliations__link" href="https://iminc.org/">
            Free Will Baptist International Missions
          </a>{" "}
          and{" "}
          <a className="affiliations__link" href="http://www.fwbnam.com/">
            North American Ministries
          </a>
          . We participate in a Cooperative Plan of giving, which enables us to
          support a range of worthy ministries, including missionaries. We
          currently support many state, national, and international missionary
          efforts.
        </p>
        <p className="affiliations__paragraph">
          Our church is also committed to the cause of Christian education and
          the development of a biblical worldview. We partner with colleges and
          other educational institutions to help accomplish this goal.
        </p>
        <p className="affiliations__paragraph">
          While these partnerships are consistent with our commitments as a
          congregation, they in no means diminish our local focus to make
          disciples and send forth labors into the harvest. We believe that God
          has chosen the local church to be the primary means for making His
          glory visible to the watching world. To that end, we strive to be
          faithful.
        </p>
      </section>
      <section className="partnersAffiliation__partners">
        <h2 className="partners__title">Our Ministry Partners</h2>
        <ul className="partners__list">
          {PartnerContents.map((partner: Partner, index: number) => (
            <PartnerCard
              key={index}
              title={partner.title}
              desc={partner.desc}
              imgSrc={partner.imgSrc}
              imgDesc={partner.imgDesc}
              extraClass={partner.extraClass}
            />
          ))}
        </ul>
      </section>
    </div>
  );
}
