import stlda from "../../assets/images/stldaLogoBig.png";
import missFWB from "../../assets/images/missouriFWB.png";
import nafwb from "../../assets/images/fwbLogo2.png";
import niangua from "../../assets/images/nianguaLogo.png";
import d6logo from "../../assets/images/d6Logo.jpg";
import welch from "../../assets/images/WelchLogo.png";
import v3logo from "../../assets/images/v3Logo2.png";
import randall from "../../assets/images/randallUniversity.png";

export interface Partner {
  title: string;
  desc: string;
  imgSrc: string;
  imgDesc: string;
  extraClass: string;
}

export const PartnerContents: Partner[] = [
  {
    title: "St. Louis District Association",
    desc: "Grace Church is a member of a local association of churches committed to fellowship, accountability, and collaborative ministry endeavors.",
    imgSrc: stlda,
    imgDesc: "St. Louis District Association of Free Will Baptists logo",
    extraClass: "partnerCard partnerCard--stlda",
  },
  {
    title: "Missouri State Association of free will baptists",
    desc: "Grace Church belongs to the St. Louis District Association, which belongs to the Missouri State Association of Free Will Baptists.",
    imgSrc: missFWB,
    imgDesc: "Missouri State Association of Free Will Baptists logo",
    extraClass: "partnerCard partnerCard--missFWB",
  },
  {
    title: "National Association of Free Will Baptists",
    desc: "Grace Church is a part of the National Association of Free Will Baptists, whose headquarters is located in Antioch, Tennessee. Free Will Baptist local churches are autonomous religious bodies who voluntarily cooperate with the larger Free Will Baptist movement for the cause of Christ.",
    imgSrc: nafwb,
    imgDesc: "National Association of Free Will Baptists logo",
    extraClass: "partnerCard partnerCard--nafwb",
  },
  {
    title: "Camp Niangua",
    desc: "Camp Niangua is our Free Will Baptist state youth camp that we have sent our youth to for years. We are proud supporters of their ministry.",
    imgSrc: niangua,
    imgDesc: "Camp Niangua logo",
    extraClass: "partnerCard partnerCard--niangua",
  },
  {
    title: "D6 Family Ministry",
    desc: "D6 Family Ministry is our church's main curriculum provider and the publishing arm of our denomination.",
    imgSrc: d6logo,
    imgDesc: "D6 Family Ministry logo",
    extraClass: "partnerCard partnerCard--d6",
  },
  {
    title: "Welch College",
    desc: "Grace Church is a proud supporter of Welch College, the flagship school of our denomination. The college is located in Nashville, Tennessee.",
    imgSrc: welch,
    imgDesc: "Welch College logo",
    extraClass: "partnerCard partnerCard--welch",
  },
  {
    title: "Vertical Three",
    desc: "Vertical Three is a national ministry partner through Randall House that facilitates our work in equipping youth to learn the Scriptures and use their gifts in music, drama, and the arts for the edification of the local church. At home, we simply call this 'CTS'--Church Training Service.",
    imgSrc: v3logo,
    imgDesc: "Vertical Three logo",
    extraClass: "partnerCard partnerCard--v3",
  },
  {
    title: "Randall University",
    desc: "Grace Church has long enjoyed a relationship with Randall, an Oklahoma-based, Christian college with strong ties to our region.",
    imgSrc: randall,
    imgDesc: "Randall University logo",
    extraClass: "partnerCard partnerCard--randall",
  },
];
