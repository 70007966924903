import React from "react";
import "./MinistryCard.scss";

export default function MinistryCard(props: {
  title: string;
  desc: string;
  imgSrc: string;
  imgDesc: string;
  extraClass: string;
  isAlternate: boolean;
}) {
  const ministryClass = !props.isAlternate
    ? `${props.extraClass}`
    : `${props.extraClass} ministryCard--ALT`;

  return (
    <div className={ministryClass}>
      <img
        src={props.imgSrc}
        alt={props.imgDesc}
        className="ministryCard__img ministryCard__img--desktop"
      />
      <section className="ministryCard__text">
        <img
          src={props.imgSrc}
          alt={props.imgDesc}
          className="ministryCard__img ministryCard__img--tablet"
        />
        <h3 className="ministryCard__title">{props.title}</h3>
        {props.desc.split("\n\n").map((paragraph) => (
          <p key={paragraph} className="ministryCard__paragraph">
            {paragraph}
          </p>
        ))}
      </section>
    </div>
  );
}
