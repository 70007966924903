import React from "react";
import "./AdminLogin.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function AdminLogin() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      adminName: "",
      adminPass: "",
    },
  });

  const navigate = useNavigate();

  const handleLogin = (content: object) => {
    async function serverLogin() {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/login`,
        content
      );
    }

    toast
      .promise(
        serverLogin(),
        {
          loading: "Loading...",
          success: "Logged in.",
          error: "Error logging in.",
        },
        { duration: 4500, className: "toast" }
      )
      .then((res) => {
        sessionStorage.setItem("token", res.data.token);
        navigate("/admin");
      })
      .catch((error) => {
        console.log("Failed to log in:", error);
      });
  };

  return (
    <div className="adminLogin">
      <h1 className="adminLogin__header">Administrator Login</h1>
      <form onSubmit={handleSubmit(handleLogin)} className="adminForm">
        <label htmlFor="adminName" className="adminForm__label">
          Username:
        </label>
        <input
          {...register("adminName")}
          type="text"
          name="adminName"
          id="adminName"
          className="adminForm__input"
          required
        />
        <label htmlFor="adminPass" className="adminForm__label">
          Password:
        </label>
        <input
          {...register("adminPass")}
          type="password"
          name="adminPass"
          id="adminPass"
          className="adminForm__input"
          required
        />
        <input type="submit" value="Log in" className="button button--admin" />
      </form>
    </div>
  );
}
