import "./AdminNav.scss";
import whiteGraceLogo from "../../assets/images/whiteGraceFull.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import adminClose from "../../assets/svg/adminX.svg";
import adminOpen from "../../assets/svg/adminMenu.svg";

export default function AdminNav() {
  const [mobileOpen, setMobileOpen] = useState(false);

  function changeMenu() {
    setMobileOpen(!mobileOpen);
  }

  const navigate = useNavigate();

  return (
    <div className="adminNav">
      <Link
        to={"/admin"}
        className="logo__link"
        onClick={() => setMobileOpen(false)}
      >
        <img
          src={whiteGraceLogo}
          alt="Grace Free Will Baptist Logo"
          className="adminNav__logo"
        />
      </Link>
      <section onClick={changeMenu} className="navMobile__trigger">
        <img
          src={mobileOpen ? adminClose : adminOpen}
          alt={mobileOpen ? "Close Menu" : "Open Menu"}
          className="trigger__icon"
        />
      </section>
      <ul
        className={
          mobileOpen ? "adminNav__list" : "adminNav__list adminNav__hidden"
        }
      >
        <li className="adminNav__item">
          <Link to={"/admin"} className="adminNav__link" onClick={changeMenu}>
            Home
          </Link>
        </li>
        <li className="adminNav__item">
          <Link to={"sermons"} className="adminNav__link" onClick={changeMenu}>
            Sermons
          </Link>
        </li>
        <li className="adminNav__item">
          <Link to={"slides"} className="adminNav__link" onClick={changeMenu}>
            Slides
          </Link>
        </li>
        <li className="adminNav__item">
          <Link to={"help"} className="adminNav__link" onClick={changeMenu}>
            Help
          </Link>
        </li>
        <li
          className="adminNav__item adminNav__item--button"
          onClick={changeMenu}
        >
          <button
            onClick={() => {
              sessionStorage.removeItem("token");
              navigate("/login");
            }}
            className="button button--adminNav"
          >
            Log Out
          </button>
        </li>
      </ul>
    </div>
  );
}
