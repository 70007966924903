import React from "react";
import "./AboutPage.scss";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import welcome from "../../assets/images/outreach.jpg";
import bird from "../../assets/images/whitegracelogo.png";
import HeroSmall from "../../components/HeroSmall/HeroSmall";

export default function AboutPage() {
  return (
    <div className="about">
      <HeroSmall content="Who We Are" classtitle="heroSmall--about" />
      <section className="about__video">
        <iframe
          src={"https://www.youtube.com/embed/5PBKCTj6NuQ"}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="about__embed"
        />
      </section>
      <section className="about__values">
        <h3 className="values__title">We strive to be a church that is</h3>
        <div className="values__wrapper">
          <section className="values__value">
            <i
              className="fa-solid fa-cross fa-8x fontIcon--desktop"
              style={{ color: "#ffffff" }}
            ></i>
            <i
              className="fa-solid fa-cross fa-6x fontIcon--tablet"
              style={{ color: "#ffffff" }}
            ></i>
            <p className="values__text">Christ Centered</p>
          </section>
          <section className="values__value">
            <img
              src={bird}
              alt="Grace Free Will Baptist Dove Logo"
              className="values__bird"
            />
            <p className="values__text">Spiritually Fruitful</p>
          </section>
          <section className="values__value">
            <i
              className="fa-solid fa-people-roof fa-8x fontIcon--desktop"
              style={{ color: "#ffffff" }}
            ></i>
            <i
              className="fa-solid fa-people-roof fa-6x fontIcon--tablet"
              style={{ color: "#ffffff" }}
            ></i>
            <p className="values__text">Multigenerational</p>
          </section>
        </div>
      </section>
      <section className="about__mission">
        <h3 className="mission__title">Our Mission</h3>
        <p className="mission__text">
          Grace Free Will Baptist Church exists to glorify the Lord Jesus Christ
          by proclaiming the Gospel and equipping believers to serve Christ's
          kingdom.
        </p>
      </section>
      <section className="about__message">
        <div className="message__content">
          <img
            src={welcome}
            alt="Man inviting another man to church"
            className="message__img message__img--tablet"
          />
          <p className="message__text">
            "What comes into our minds when we think about God is the most
            important thing about us." A.W. Tozer wrote those words many years
            ago, and they're just as true now as they were then. What we think
            about God determines our lives now, our hope for the future, and how
            we deal with our past.
          </p>
          <p className="message__text">
            Regardless of where you've been, where you are, and where you're
            going, our church's goal is to introduce (or reintroduce) you to who
            Jesus is, what His Word says, and the difference He is making in our
            lives.
          </p>
          <p className="message__text">
            We are a mid-sized, evangelical congregation who gathers in Arnold,
            but ministers to people who live throughout Jefferson County and
            South County.
          </p>
          <Link to="/plan" className="message__link">
            <Button
              content="Plan your visit"
              classtitle="button button--about"
            />
          </Link>
        </div>
        <img
          src={welcome}
          alt="Man walking into sanctuary"
          className="message__img message__img--desktop"
        />
      </section>
    </div>
  );
}
