import React from "react";
import "./HeroSmall.scss";

export default function HeroSmall(props: {
  content: string;
  classtitle: string;
}) {
  return (
    <div className={`heroSmall ${props.classtitle}`}>
      <h2 className="heroSmall__text">{props.content}</h2>
      <section className="heroSmall__overlay"></section>
    </div>
  );
}
