export const WatchItems = [
  {
    title: "Plan your visit",
    path: "/plan",
    cName: "dropdown__link",
  },
  {
    title: "Sermons",
    path: "/sermons",
    cName: "dropdown__link",
  },
  {
    title: "YouTube",
    path: "https://www.youtube.com/@moreofgrace_arnold",
    cName: "dropdown__link",
    external: true,
  },
  {
    title: "Facebook",
    path: "https://www.facebook.com/moreofgrace",
    cName: "dropdown__link",
    external: true,
  },
];
