import React from "react";
import "./GivePage.scss";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import Button from "../../components/Button/Button";
import appStore from "../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import playStore from "../../assets/images/googlePlay.png";
import tithely from "../../assets/images/Tithely-Leaf-White.png";
import { Link } from "react-router-dom";

export default function GivePage() {
  return (
    <div className="givePage">
      <HeroSmall classtitle="heroSmall--give" content="Give" />
      <h2 className="givePage__header">Ways to give</h2>
      <section className="givePage__options">
        <div className="givePage__option">
          <section className="option__iconWrapper">
            <i className="fa-solid fa-dollar-sign fa-6x fontIcon--desktop"></i>
            <i className="fa-solid fa-dollar-sign fa-5x fontIcon--tablet"></i>
          </section>
          <h3 className="option__title">give online</h3>
          <p className="option__desc">
            You can make a financial gift to the ministry by clicking the button
            below.
          </p>
          <section className="option__buttonWrapper">
            <a
              href="https://give.tithe.ly/?formId=ca54c0de-6864-11ee-90fc-1260ab546d11"
              className="option__button--give"
            >
              give online
            </a>
          </section>
        </div>
        <div className="givePage__option">
          <section className="option__iconWrapper">
            <img src={tithely} alt="Tithe.ly Leaf" className="option__img" />
          </section>
          <h3 className="option__title">Tithe.ly app</h3>
          <p className="option__desc">
            By downloading the Tithe.ly app, you can give directly from your
            phone anywhere.
          </p>

          <a
            href="https://itunes.apple.com/us/app/tithe-ly/id694740700?mt=8"
            className="option__link"
          >
            <img
              src={appStore}
              alt="Download on the App Store"
              className="option__appStore"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ly.tithe.tithely"
            className="option__link"
          >
            <img
              src={playStore}
              alt="Download on the Play Store"
              className="option__playStore"
            />
          </a>
        </div>
        <div className="givePage__option">
          <section className="option__iconWrapper">
            <i className="fa-solid fa-place-of-worship fa-6x fontIcon--desktop"></i>
            <i className="fa-solid fa-place-of-worship fa-5x fontIcon--tablet"></i>
          </section>
          <h3 className="option__title">give in person</h3>
          <p className="option__desc">
            You can give during our Sunday services when we collect tithes and
            offerings.
          </p>
          <section className="option__buttonWrapper">
            <Link to="/plan">
              <Button
                classtitle="button button--give"
                content="take me there"
              />
            </Link>
          </section>
        </div>
      </section>
    </div>
  );
}
