import "./Homepage.scss";
import graceLogoFull from "../../assets/images/graceLogo.png";
import Button from "../../components/Button/Button";
import worship from "../../assets/images/worship.jpg";
import womenPray from "../../assets/images/womenPray.jpg";
import { Link } from "react-router-dom";
import ContactForm from "../../components/ContactForm/ContactForm";
import Carousel from "../../components/Carousel/Carousel";
import { useEffect, useState } from "react";
import axios from "axios";
import { carouselItem } from "../../types/carouselInterface";

export default function Homepage() {
  const [carouselActive, setCarouselActive] = useState<boolean>(false);
  const [carouselItems, setCarouselItems] = useState<Array<carouselItem>>([]);

  useEffect(() => {
    async function getCarouselItems() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/carousel/list`
        );

        if (data.length > 0) {
          setCarouselItems(data);
          setCarouselActive(true);
        } else {
          return;
        }
      } catch (error) {
        console.error("Failed to fetch carousel items:", error);
      }
    }

    getCarouselItems();
  }, []);

  return (
    <div className="homepage">
      <section className="homepage__hero">
        {carouselActive ? (
          <Carousel items={carouselItems} />
        ) : (
          <div className="hero__container">
            <section className="hero__text">
              <h3 className="hero__text--small">Growing in</h3>
              <h1 className="hero__text--big">Grace</h1>
            </section>
          </div>
        )}
      </section>
      <section className="homepage__welcome">
        <div className="welcome__text">
          <h2 className="welcome__heading">Discover Jesus with us!</h2>
          <p className="welcome__phrase">
            No matter who you are, where you've come from, or what you're going
            through, let us help you find your future.
          </p>
          <Link to="/about">
            <Button content="learn more" classtitle="button button--welcome" />
          </Link>
        </div>
        <img
          src={graceLogoFull}
          alt="Grace Free Will Baptist Logo"
          className="welcome__img"
        />
      </section>
      <section className="homepage__redirects">
        <div className="redirect__card redirect__card--top">
          <section className="redirect__text">
            <h3 className="redirect__heading">Plan your visit</h3>
            <p className="redirect__phrase">
              We'd be delighted to have you join us for a service. Our goal is
              to help your visit be worry-free and spiritually impactful. Click
              below for some basic information about our location, gatherings,
              people, and ministries.
            </p>
            <Link to="/plan">
              <h3 className="redirect__heading redirect__heading--plan">
                Plan your visit
              </h3>
              <Button
                content="Plan now"
                classtitle="button button--planVisit"
              />
            </Link>
          </section>
          <img
            src={worship}
            alt="Church member ready to welcome"
            className="redirect__img"
          />
        </div>
        <div className="redirect__card redirect__card--bottom">
          <section className="redirect__text">
            <h3 className="redirect__heading">Check out our ministries</h3>
            <p className="redirect__phrase">
              The ministries of our church are as numerous as our members. We
              believe{" "}
              <span className="redirect__phrase--underline">every member</span>{" "}
              is called to ministry, differing only according to our gifts,
              opportunities, and the needs of Christ's Kingdom. However, learn
              more about a few of our church's ministries by clicking below.
            </p>
            <Link to="/ministries">
              <h3 className="redirect__heading redirect__heading--ministry">
                <span className="redirect__subheading">Check out our</span>
                ministries
              </h3>
              <Button
                content="learn more"
                classtitle="button button--ministry"
              />
            </Link>
          </section>
          <img
            src={womenPray}
            alt="Women praying together"
            className="redirect__img"
          />
        </div>
      </section>
      <section className="homepage__socials">
        <h2 className="social__heading">connect with us online</h2>
        <div className="social__card social__card--youtube">
          <Link
            className="social__wrapper"
            to="https://www.youtube.com/@moreofgrace_arnold"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="social__phrase">
              watch our services posted each week on
            </p>
            <h3 className="social__title">youtube</h3>
          </Link>
        </div>
        <div className="social__card social__card--facebook">
          <Link
            className="social__wrapper"
            to="https://www.facebook.com/moreofgrace"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="social__phrase">follow us for more updates on</p>
            <h3 className="social__title">facebook</h3>
          </Link>
        </div>
      </section>
      <section className="homepage__contact">
        <div className="homeContact__content">
          <h2 className="homeContact__header">Contact Us</h2>
          <p className="homeContact__text">
            Whether you have questions, a prayer request, or just want to say
            hi, we would love to hear from you!
          </p>
          <section className="homeContact__phone">
            <i
              className="fa-solid fa-phone fa-lg"
              style={{ color: "#ffffff" }}
            ></i>
            <a href="tel:636-296-2380" className="homeContact__number">
              636-296-2380
            </a>
          </section>
        </div>
        <ContactForm classTitle="home" />
      </section>
    </div>
  );
}
