import { Link } from "react-router-dom";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./PlanVisitPage.scss";
import Button from "../../components/Button/Button";
import foyer from "../../assets/images/foyer.jpg";
import FAQCard from "../../components/FAQCard/FAQCard";
import { FAQContent } from "../../components/FAQCard/FAQContent";
import ContactForm from "../../components/ContactForm/ContactForm";

export default function PlanVisitPage() {
  return (
    <div className="plan">
      <HeroSmall content="Plan Your Visit" classtitle="heroSmall--plan" />
      <section className="plan__welcome">
        <h2 className="welcome__title">You're Welcome And Wanted Here!</h2>
        <div className="welcome__wrapper">
          <aside className="welcome__aside">
            <p className="welcome__text">
              Whether you're passing through the area, looking for answers to
              serious questions, or you've just moved to the community, it's our
              prayer that you will give Grace Church an opportunity to get to
              know you. There's no better way to get to know us than to visit.
              We've provided some answers below to some of the questions that
              churchgoers often have, but perhaps you have other questions. Feel
              free to contact us down below, message us on{" "}
              <a
                href="https://www.facebook.com/moreofgrace"
                className="welcome__text--link"
              >
                Facebook
              </a>
              , call (636-296-2380), or just show up! We will be waiting for you
              with a special gift, a smile, and with God's help, answers to the
              questions that matter most.
            </p>
          </aside>
          <img
            src={foyer}
            alt="Church member holding front door open ready to welcome"
            className="welcome__img welcome__img--desktop"
          />
        </div>
      </section>
      <section className="plan__timeMap">
        <div className="timeMap__times">
          <h3 className="timeMap__title">Service Times</h3>
          <ul className="timeMap__list">
            <li className="timeMap__item">
              <p className="timeMap__service">Sunday School:</p>
              <p className="timeMap__time">9:30AM</p>
            </li>
            <li className="timeMap__item">
              <p className="timeMap__service">Sunday Worship:</p>
              <p className="timeMap__time">10:30 AM</p>
            </li>
            <li className="timeMap__item">
              <p className="timeMap__service">Midweek Study/Prayer:</p>
              <p className="timeMap__time">10:00 AM</p>
            </li>
            <li className="timeMap__item">
              <p className="timeMap__service">
                Online (Contact Church Office):
              </p>
              <p className="timeMap__time">7:00 PM</p>
            </li>
          </ul>
          <div className="timeMap__map timeMap__map--mobile">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6249.744697909164!2d-90.36703922452031!3d38.44441737182423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d8c662dd6a5b21%3A0xfc3fad7dc9974f38!2sGrace%20Free%20Will%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1715885887347!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="GraceMapPlan"
            ></iframe>
          </div>
          <p className="timeMap__address">2258 Tenbrook Rd, Arnold, MO 63010</p>
          <Link
            to={"https://maps.app.goo.gl/WNK77fkgr3S8hMiv8"}
            className="timeMap__link"
          >
            <Button content="take me there" classtitle="button button--map" />
          </Link>
        </div>
        <div className="timeMap__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6249.744697909164!2d-90.36703922452031!3d38.44441737182423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d8c662dd6a5b21%3A0xfc3fad7dc9974f38!2sGrace%20Free%20Will%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1715885887347!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="GraceMapPlan"
          ></iframe>
        </div>
      </section>
      <section className="plan__faq">
        <h2 className="faq__title">frequently asked questions</h2>
        {FAQContent.map(({ question, answer }) => (
          <FAQCard question={question} answer={answer} key={Math.random()} />
        ))}
        <hr className="FAQCard__line" />
      </section>
      <section className="plan__contact">
        <div className="planContact__content">
          <h2 className="planContact__header">Have a question?</h2>
          <p className="planContact__text">We would love to hear from you! </p>
        </div>
        <ContactForm classTitle="plan" />
      </section>
    </div>
  );
}
