import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import AdminNav from "../AdminNav/AdminNav";
import AdminFoot from "../AdminFoot/AdminFoot";
import ModalSermon from "../ModalSermon/ModalSermon";
import ModalCarousel from "../ModalCarousel/ModalCarousel";
import { ModalsContextType } from "../../types/modalsInterface";

export default function ProtectedRoute() {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [resolved, setResolved] = useState<boolean>(false);
  const [modSermonOpen, setModSermonOpen] = useState<boolean>(false);
  const [modCarouselOpen, setModCarouselOpen] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = sessionStorage.getItem("token");
        await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/checkAuth`, {
          headers: { Authorization: token },
        });
        setAuthenticated(true);
        setResolved(true);
      } catch (error) {
        console.log(error);
        setAuthenticated(false);
        setResolved(true);
      }
    };

    checkAuth();
  }, []);

  if (!resolved) {
    return <h1>Loading...</h1>;
  }

  return authenticated ? (
    <div className="protRoute" style={{ position: "relative" }}>
      {modSermonOpen ? (
        <ModalSermon setModSermonOpen={setModSermonOpen} />
      ) : (
        <></>
      )}
      {modCarouselOpen ? (
        <ModalCarousel setModCarouselOpen={setModCarouselOpen} />
      ) : (
        <></>
      )}
      <AdminNav />
      <Outlet
        context={
          {
            setModSermonOpen,
            setModCarouselOpen,
          } satisfies ModalsContextType
        }
      />
      <AdminFoot />
    </div>
  ) : (
    <Navigate to="/login" />
  );
}
