import "./Footer.scss";
import whiteGraceLogo from "../../assets/images/whiteGraceFull.png";
import graceBird from "../../assets/images/whitegracelogo.png";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <section className="footer__map">
        <div className="map__wrapper">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6249.744697909164!2d-90.36703922452031!3d38.44441737182423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f20!3m3!1m2!1s0x87d8c662dd6a5b21%3A0xfc3fad7dc9974f38!2sGrace%20Free%20Will%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1715885887347!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map Card"
          ></iframe>
        </div>
        <div className="map__content">
          <img
            src={graceBird}
            alt="Grace Free Will Baptist Logo Minimal"
            className="map__logo"
          />
          <h3 className="map__title">Grace Free Will Baptist church</h3>
          <p className="map__address">2258 Tenbrook Rd, Arnold, MO 63010</p>
          <section className="map__phone">
            <i
              className="fa-solid fa-phone fa-lg"
              style={{ color: "#ffffff" }}
            ></i>
            <a href="tel:636-296-2380" className="map__number">
              636-296-2380
            </a>
          </section>
        </div>
      </section>
      <section className="footer__foot">
        <div className="foot__wrapper">
          <section className="foot__logo">
            <img src={whiteGraceLogo} alt="" className="foot__img" />
            <p className="foot__copyright">
              © Copyright 2024 Grace free will Baptist church All rights
              reserved
            </p>
          </section>
          <section className="foot__times">
            <p className="foot__title">Weekly Gatherings</p>
            <ul className="foot__list">
              <li className="foot__item">
                <p className="foot__service">Sunday School:</p>
                <p className="foot__time">9:30 AM</p>
              </li>
              <li className="foot__item">
                <p className="foot__service">Sunday Worship:</p>
                <p className="foot__time">10:30 AM</p>
              </li>
              <li className="foot__item">
                <p className="foot__service">Midweek Study/Prayer:</p>
                <p className="foot__time">10:00 AM</p>
              </li>
              <li className="foot__item">
                <p className="foot__service">Online (Contact Church Office):</p>
                <p className="foot__time">7:00 PM</p>
              </li>
            </ul>
          </section>
          <section className="foot__links">
            <p className="foot__title">Quicklinks</p>
            <Link to={"/"} className="foot__link">
              Home
            </Link>
            <Link to={"/plan"} className="foot__link">
              I'm new
            </Link>
            <Link to={"/about"} className="foot__link">
              About Us
            </Link>
            <Link to={"/ministries"} className="foot__link">
              Ministries
            </Link>
            <Link to={"/sermons"} className="foot__link">
              Watch
            </Link>
            <Link to={"/give"} className="foot__link">
              Give
            </Link>
          </section>
          <section className="foot__contact">
            <Link to={"/contact"} className="foot__contact--link">
              <Button content="contact us" classtitle="button button--footer" />
            </Link>
          </section>
        </div>
      </section>
    </div>
  );
}
