import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import ScrollFix from "./components/ScrollFix/ScrollFix";
import Homepage from "./pages/Homepage/Homepage";
import Footer from "./components/Footer/Footer";
import AboutPage from "./pages/AboutPage/AboutPage";
import PlanVisitPage from "./pages/PlanVisitPage/PlanVisitPage";
import HistoryPage from "./pages/HistoryPage/HistoryPage";
import MinistriesPage from "./pages/MinistriesPage/MinistriesPage";
import BeliefsPage from "./pages/BeliefsPage/BeliefsPage";
import LeadershipPage from "./pages/LeadershipPage/LeadershipPage";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import SermonsPage from "./pages/SermonsPage/SermonsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import NotFound from "./pages/NotFound/NotFound";
import GivePage from "./pages/GivePage/GivePage";
import VideoPage from "./pages/VideoPage/VideoPage";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminHome from "./pages/AdminHome/AdminHome";
import AdminSermon from "./pages/AdminSermon/AdminSermon";
import AdminSlide from "./pages/AdminSlide/AdminSlide";
import AdminHelp from "./pages/AdminHelp/AdminHelp";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

const PublicLayout = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
  </>
);

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollFix />

        <Routes>
          <Route path="/" element={<PublicLayout />}>
            <Route index element={<Homepage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/plan" element={<PlanVisitPage />} />
            <Route path="/ministries" element={<MinistriesPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/beliefs" element={<BeliefsPage />} />
            <Route path="/leadership" element={<LeadershipPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/sermons" element={<SermonsPage />} />
            <Route path="/sermon/:sermonId" element={<VideoPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/give" element={<GivePage />} />
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/*" element={<NotFound />} />
          </Route>

          <Route path="/admin" element={<ProtectedRoute />}>
            <Route index element={<AdminHome />} />
            <Route path="sermons" element={<AdminSermon />} />
            <Route path="slides" element={<AdminSlide />} />
            <Route path="help" element={<AdminHelp />} />
          </Route>
        </Routes>
      </Router>

      <Toaster />
    </div>
  );
}

export default App;
